<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotSupplier' }">供应商管理</el-breadcrumb-item>
          <el-breadcrumb-item>新增供应商</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="供应商名称" prop="supplier">
                <el-input v-model="form.supplier" placeholder="请输入供应商名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="供应商编码" prop="supplierCode">
                <el-input v-model="form.supplierCode" placeholder="请输入供应商编码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="MQTT账号" prop="">
                <el-input v-model="form.mqttAccount" placeholder="请输入MQTT账号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="MQTT密码" prop="">
                <el-input v-model="form.mqttPwd" placeholder="请输入MQTT密码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="备注" prop="note">
                <el-input v-model="form.note" placeholder="请输入备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/iotSupplier')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        supplier:[{ required: true, message: '必填项', trigger: 'blur' }],
        supplierCode:[{ required: true, message: '必填项', trigger: 'blur' }],
        mqttAccount:[{ required: true, message: '必填项', trigger: 'blur' }],
        mqttPwd:[{ required: true, message: '必填项', trigger: 'blur' }],
        note:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      disabled: false,
      allFileList: [],
    };
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$ajax.post("iotSupplierCreate", {
            supplier: this.form.supplier,
            supplierCode: this.form.supplierCode,
            mqttAccount: this.form.mqttAccount?this.form.mqttAccount:null,
            mqttPwd: this.form.mqttPwd?this.form.mqttPwd:null,
            note: this.form.note,
          }).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/iotSupplier");
          }).catch(err=>{
            this.loading = false;
          })
        }
      })
    },
    uploadChange(file, fileList) {
      this.allFileList = fileList;
      console.log(fileList);
    },
    handleRemove(file) {
      function checkAdult(item) {
        return item.uid != file.uid;
      }
      this.allFileList = this.allFileList.filter(checkAdult);

      console.log(this.allFileList);
    }
  },
  mounted() {
    
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin-top: 10px;
}
</style>